import React, { useState } from 'react'
import styled from 'styled-components'

// Utils
import subscribe from 'klaviyo-subscribe'
import { validateEmail } from '../../utils'

// Hooks
import useDictionaryCtx from '../../hooks/context/useDictionaryCtx'

// Components
import ArrowRight from '../graphics/ArrowRight'

const StyledEmailCapture = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled.input`
  display: ${props => (props.hidden ? 'none' : 'block')};
`

const SuccessMessage = styled.div``

const Button = styled.button`
  display: ${props => (props.hidden ? 'none' : 'block')};
`

const WebinarCapture = ({ className }) => {
  const [subscribed, setSubscribed] = useState(false)
  const [email, setEmail] = useState('')
  const dictionary = useDictionaryCtx()

  return (
    <StyledEmailCapture className={className}>
      <StyledInput
        hidden={subscribed}
        onChange={e => setEmail(e.target.value)}
        placeholder={dictionary.emailPlaceholder}
        type='email'
      />
      <Button
        aria-label='submit email'
        type='submit'
        hidden={subscribed}
        onClick={async e => {
          if (validateEmail(email)) {
            e.preventDefault()
            try {
              const res = await subscribe('URcTDG', email, {
                $first_name: '',
              })

              setSubscribed(true)
            } catch (error) {}
          }
        }}
      >
        <ArrowRight />
      </Button>
      <SuccessMessage hidden={!subscribed}>
        {dictionary.emailSubscribeMessage}
      </SuccessMessage>
    </StyledEmailCapture>
  )
}

export default WebinarCapture
