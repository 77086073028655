import React from 'react'

const Whatsapp = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26.016 26.02'>
    <g transform='translate(-264.957 -353.743)'>
      <path
        d='M290.966,366.1a12.773,12.773,0,0,0-25.523-.233c-.006.186-.012.371-.012.556a12.575,12.575,0,0,0,1.829,6.547l-2.3,6.8,7.073-2.248a12.788,12.788,0,0,0,18.941-11.1C290.972,366.31,290.972,366.2,290.966,366.1ZM278.2,377.072a10.7,10.7,0,0,1-5.907-1.763l-4.125,1.315,1.339-3.958a10.551,10.551,0,0,1-2.044-6.248,10.139,10.139,0,0,1,.053-1.034,10.742,10.742,0,0,1,21.386.2c.024.275.036.55.036.831A10.708,10.708,0,0,1,278.2,377.072Z'
        fill='#e3edff'
        fillRule='evenodd'
      />
      <path
        d='M284.055,368.977c-.317-.155-1.854-.909-2.141-1.01s-.5-.156-.7.155-.813,1.01-.993,1.214-.364.233-.681.078a8.487,8.487,0,0,1-2.517-1.543,9.261,9.261,0,0,1-1.74-2.146c-.179-.311-.018-.479.137-.634s.311-.365.473-.544c.042-.054.077-.1.113-.15a3.284,3.284,0,0,0,.2-.37.561.561,0,0,0-.023-.544c-.078-.156-.706-1.686-.969-2.308s-.52-.515-.7-.515-.389-.029-.6-.029a1.16,1.16,0,0,0-.837.388,3.481,3.481,0,0,0-1.094,2.6,3.976,3.976,0,0,0,.161,1.059,7.083,7.083,0,0,0,1.118,2.152,12.853,12.853,0,0,0,5.345,4.693c3.187,1.238,3.187.825,3.761.771a3.123,3.123,0,0,0,2.11-1.476,2.569,2.569,0,0,0,.185-1.477C284.575,369.21,284.365,369.132,284.055,368.977Z'
        fill='#e3edff'
        fillRule='evenodd'
      />
    </g>
  </svg>
)

export default Whatsapp
