import React, { useState } from 'react'
import styled from 'styled-components'
import {
  WhatsappShareButton,
  FacebookShareButton,
  InstapaperShareButton,
} from 'react-share'
import {
  mobileVW,
  desktopBreakpoint,
  colors,
  letterSpacing,
  font,
} from '../../styles'

// Utils
import { getPathname } from '../../utils'

// Hooks
import useDictionaryCtx from '../../hooks/context/useDictionaryCtx'

// Components
import Whatsapp from '../graphics/Whatsapp'
import Facebook from '../graphics/Facebook'
import Instagram from '../graphics/Instagram'

const StyledArticleShare = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: ${letterSpacing(6)};
  margin-top: ${mobileVW(10)};
  margin-bottom: ${mobileVW(70)};

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 ${mobileVW(70)};
    @media (min-width: ${desktopBreakpoint}) {
      width: auto;
      padding: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 48px;
    margin-bottom: 70px;
    flex-direction: row;
    padding-left: 158px;
  }
`
const ShareTitle = styled.h4`
  font-family: ${font.ben};
  text-transform: lowercase;
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(20)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 20px;
  }
`

const ButtonWrapper = styled.div`
  background: ${colors.beigeUltraLight};
  width: ${mobileVW(40)};
  height: ${mobileVW(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media (min-width: ${desktopBreakpoint}) {
    margin-left: 16px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:hover {
      background: ${colors.beigeLight};
    }
  }
  svg {
    width: ${mobileVW(16.67)};

    @media (min-width: ${desktopBreakpoint}) {
      width: 17px;
    }

    path {
      fill: ${colors.brownDarkest};
    }
  }
`
const CopyButtonWrapper = styled.div`
  font-family: ${font.marby};
  font-size: ${mobileVW(14)};
  margin-left: ${mobileVW(20)};

  @media (min-width: ${desktopBreakpoint}) {
    font-family: ${font.marby};
    font-size: 13px;
    margin-left: 20px;
  }
`

const CopyButton = styled.button``

const ArticleShare = ({ slug, title }) => {
  const { node_locale, share, copyLink, copied } = useDictionaryCtx()
  const [userCopied, setUserCopied] = useState(false)
  const ArticleLink = `https://cycle.care${getPathname(node_locale, slug)}`
  const copy = () => {
    navigator.clipboard.writeText(ArticleLink)
    setUserCopied(true)
  }
  return (
    <StyledArticleShare>
      <ShareTitle>{share}</ShareTitle>
      <div>
        <ButtonWrapper>
          <InstapaperShareButton title={title} url={ArticleLink}>
            <Instagram />
          </InstapaperShareButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <FacebookShareButton quote={title} url={ArticleLink}>
            <Facebook />
          </FacebookShareButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <WhatsappShareButton title={title} url={ArticleLink}>
            <Whatsapp />
          </WhatsappShareButton>
        </ButtonWrapper>
      </div>
    </StyledArticleShare>
  )
}

export default ArticleShare
