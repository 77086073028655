import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import {
  colors,
  mobileVW,
  desktopVW,
  font,
  desktopBreakpoint,
  headerHeight,
} from '../styles'

// Utils
import { getPathname } from '../utils'

// Hooks
import { useDictionaryCtx, useLayoutCtx } from '../hooks/context'

// Components
import Image from 'gatsby-image'
import RichText from '../components/shared/RichText'
import SEO from '../components/shared/SEO'
import ArticleProductCard from '../components/shared/uiComponents/articleProductCard'
import ArticleShare from '../components/articles/ArticleShare'
import WebinarCapture from '../components/articles/WebinarCapture'
import ArticleProductSwiper from '../components/shared/swipers/articleProductSwiper'
import SaveArticleButton from '../components/shared/uiComponents/saveArticleButton'
import SectionBlock from '../components/shared/modules/sectionBlock'

const ArticleContainer = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    margin: auto;
    .section_block {
      width: 100%;

      h3 {
        max-width: 645px;
      }
    }
    padding-bottom: 146px;
  }
`

const StyledArticle = styled.article`
  padding-top: ${headerHeight.mobile}px;
  @media (min-width: ${desktopBreakpoint}) {
    padding-top: calc(${headerHeight.desktop}px + 68px);
    max-width: 1280px;
    margin: auto;
  }
`

const Container = styled.div`
  // padding: ${mobileVW(32)} ${mobileVW(16)};
  // @media (min-width: ${desktopBreakpoint}) {
  //   padding: 0 0 48px 100px;
  // }
`

const HeroMobile = styled.div`
  color: white;
  position: relative;
  height: ${mobileVW(310)};

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const SectionTitle = styled.h3`
  font-family: ${font.ben};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  text-align: center;
  margin: 0 auto ${mobileVW(24)} 0;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    text-align: left;
    font-size: 30px;
    margin: 0;
    margin-bottom: 25px;
    line-height: 39px;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  .gatsby-image-wrapper {
    height: 100%;

    img {
      object-fit: cover !important;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    .gatsby-image-wrapper {
      height: unset;
      img {
        object-fit: contain;
      }
    }
  }
`

const PhotoBy = styled.div`
  position: absolute;
  font-family: ${font.larsseit};
  color: ${colors.beigeUltraLight};
  bottom: ${mobileVW(16)};
  right: ${mobileVW(16)};
  padding-left: ${mobileVW(16)};

  @media (min-width: ${desktopBreakpoint}) {
    width: ${({ aspectRatio }) => aspectRatio * 480}px;
    bottom: 16px;
    transition: opacity 0.2s ease-in-out;
    opacity: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding-left: ${desktopVW(16)};
  }
`

const Heading = styled.h1`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  font-weight: normal;
  text-transform: lowercase;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
  }
`

const Details = styled.div`
  display: flex;
  align-items: center;
  font-family: ${font.larsseit};
  text-transform: uppercase;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  margin: ${mobileVW(8)} auto 0 0;
  color: #c4c4c4;

  span {
    height: ${mobileVW(3)};
    width: ${mobileVW(3)};
    margin: 0 ${mobileVW(8)};
    background: #c4c4c4;
    border-radius: 50%;
    @media (min-width: ${desktopBreakpoint}) {
      height: 3px;
      width: 3px;
      margin: 0 16px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 24px;
    margin: 26px auto 0 0;
  }
`

const Time = styled.div`
  display: flex;
  svg {
    width: ${mobileVW(20)};
    height: ${mobileVW(20)};
    margin-right: ${mobileVW(8)};
    @media (min-width: ${desktopBreakpoint}) {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
`
const Date = styled.div``

const Writers = styled.div`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  color: ${colors.brownDarkest};
  padding-bottom: ${mobileVW(36)};

  h3 {
    text-transform: uppercase;
    font-size: ${mobileVW(14)};
    line-height: ${mobileVW(24)};
    color: ${colors.brownDark};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
  }
`

const Writer = styled.div``

const Expert = styled(Link)`
  margin-top: ${mobileVW(2)};
  display: flex;
  align-items: center;
  line-height: ${mobileVW(21.6)};
  border: solid ${colors.beigeUltraLight} ${mobileVW(1)};
  border-radius: ${mobileVW(50)};
  .gatsby-image-wrapper {
    width: ${mobileVW(36)};
    height: ${mobileVW(36)};
    border: solid ${mobileVW(3)} white;
    border-radius: 50%;
    margin-right: ${mobileVW(16)};
    @media (min-width: ${desktopBreakpoint}) {
      width: 36px;
      height: 36px;
      margin-right: 16px;
      border: solid 3px white;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 2px;
    margin-left: 0;
    line-height: 1;
    width: fit-content;
    padding-right: 26px;
    border-radius: 50px;
    border: solid ${colors.beigeUltraLight} 1px;
  }
`

const Preview = styled.div`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(18)};
  line-height: ${mobileVW(26)};
  margin-bottom: ${mobileVW(32)};
  @media (min-width: ${desktopBreakpoint}) {
    line-height: 32px;
    font-size: 20px;
    margin-bottom: 32px;
    flex-basis: 40%;
    position: sticky;
    position: -webkit-sticky;
    align-self: flex-start;
    top: calc(108px + ${headerHeight.desktop}px);
  }
`

const Text = styled.div`
  color: ${colors.brownDarkest};
  font-family: ${font.larsseit};
  text-align: left;
  padding: 0 ${mobileVW(16)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    padding-left: 158px;
  }

  > * {
    font-family: ${font.larsseit};
  }

  a {
    text-decoration: underline;
    color: ${colors.orange};
  }

  p,
  h2 {
    display: block;
  }

  h3 {
    font-family: ${font.ben};
    font-size: ${mobileVW(28)};
    margin: ${mobileVW(31)} 0;
    line-height: 1;
    text-transform: lowercase;
    font-weight: normal;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 28px;
      line-height: 31px;
      margin: 24px 0;
    }
  }

  h2 {
    text-transform: lowercase;
    font-family: ${font.ben};
    font-size: ${mobileVW(30)};
    line-height: 1;
    margin-bottom: ${mobileVW(33)};
    font-weight: normal;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 36px;
      margin: 0.75em 0 0.5em;
    }
  }

  h6 {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: ${mobileVW(20)};
    margin: ${mobileVW(16)} 0;
    font-weight: normal;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 20px;
      margin: 16px 0;
    }
  }

  p {
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(22)};
    margin-bottom: ${mobileVW(24)};

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
    }
  }

  b {
    font-weight: 700;
  }

  ul {
    list-style-type: disc;
    margin: 0 ${mobileVW(20)} ${mobileVW(24)};

    p {
      margin-bottom: 0;
    }

    @media (min-width: ${desktopBreakpoint}) {
      margin: 0 30px 24px;
    }
  }

  img {
    width: 100%;
    border-radius: ${mobileVW(15)};
    overflow: hidden;

    @media (min-width: ${desktopBreakpoint}) {
      border-radius: 28px;
      width: 840px;
      max-width: 50%;
    }
  }
`

const WebinarForm = styled.div`
  z-index: 1;
  display: block;
  overflow: hidden;
  width: 80vw;
  margin-left: 10vw;

  @media (min-width: ${desktopBreakpoint}) {
    top: 68.25vw;
    left: 41.5vw;
    width: 43vw;
    position: absolute;
    margin: 0vw 16vw 0 0vw;
    height: 31.25vw;
  }
`

const WebinarTitle = styled.p`
  font-size: 3.733333333333334vw;
  line-height: 6.4vw;
  margin-bottom: 6.4vw;
  color: #254f40;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 1.09375vw;
    line-height: 1.7187500000000002vw;
    margin-bottom: 1.25vw;
  }
`

const RelatedProductsMobile = styled.div`
  padding: 0 0 ${mobileVW(40)} ${mobileVW(16)};
  h2 {
    text-align: center;
    font-size: ${mobileVW(36)};
    line-height: ${mobileVW(39)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const RelatedProductsInner = styled.div`

  .product_card {
    justify-content: space-between;
  }
  @media (min-width: ${desktopBreakpoint}) {
    position: sticky;
    top 72px;

    .product_card {
      h3 {
        color: ${colors.brownDark};
        font-size: 28px;
        margin-top: 9px;
      }
    }
  }
`

const ArticlesWrapper = styled.div`
  background: ${colors.beigeLight};
  padding: ${mobileVW(48)} 0 ${mobileVW(48)} ${mobileVW(16)};
  text-align: center;

  h2 {
    color: white;
  }

  .product_card {
    width: ${mobileVW(262)};
    height: ${mobileVW(360)};
    padding: ${mobileVW(11)} ${mobileVW(10)} ${mobileVW(20)};
    border-radius: ${mobileVW(10)};
    min-height: auto;

    * {
      text-align: left;
    }

    .button_wrapper {
      display: none;
    }
    @media (min-width: ${desktopBreakpoint}) {
      width: 262px;
      height: 436px;
      padding: 11px 10px 20px;
      border-radius: 10px;
    }

    .card-image-wrapper {
      width: ${mobileVW(242)};
      height: ${mobileVW(242)};
      @media (min-width: ${desktopBreakpoint}) {
        width: 242px;
        min-height: 242px;
        max-height: 242px;
      }
    }

    h3 {
      -webkit-line-clamp: 1;
      @media (min-width: ${desktopBreakpoint}) {
        -webkit-line-clamp: 2;
        font-size: 18px;
        line-height: 21px;
        margin: 16px auto 5px;
      }
    }

    h3 + div {
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 14px;
        line-height: 24px;
        margin-top: 0;
      }
    }

    a > div {
      text-align: center;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 88px 0;
    h2 {
      margin-bottom: 38px;
    }

    .swiper-container {
      max-width: 866px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    .bookmark {
      display: contents;
      svg path {
        stroke: ${colors.brownDarkest};
      }
    }
    margin-left: 30px;
  }
`

const DesktopHeaderGrid = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: grid;
    grid-template-columns: minmax(auto, 670px) minmax(auto, 610px);
  }
`
const DesktopContentGrid = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: grid;
    grid-template-columns: minmax(auto, 906px) minmax(374px, 1fr);
    margin-top: 80px;
  }
`
const RelatedProductsColumn = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    padding-left: 56px;
    padding-bottom: 48px;
    h3 {
      margin-bottom: 0;
    }
  }
`

const Left = styled.div`
  padding: ${mobileVW(35)} ${mobileVW(16)} 0;
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 0 168px;
  }
`
const Right = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    overflow: hidden;
    margin-left: 24px;

    .gatsby-image-wrapper {
      max-height: 480px;
    }
  }
`
const ImageWrapperDesktop = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    position: relative;
    margin-top: 280px;
  }
`

const ProductDesktopWrapper = styled.div``

const Article = ({
  data: {
    contentfulArticle: {
      seoTitle,
      seoDescription,
      title,
      date,
      slug,
      id,
      relatedProduct,
      relatedProducts,
      writer,
      photoBy,
      coCreated,
      approvedBy,
      image,
      content,
      relatedArticles,
      label,
      videoLink,
      preview,
    },
  },
}) => {
  const dictionary = useDictionaryCtx()
  const { node_locale, articlePagesFooterBlocks } = useLayoutCtx()
  const location = useLocation()
  const url = location.pathname

  const webinar_url = url.includes(
    'gratis-webinar-hevig-menstrueel-bloedverlies',
  )

  const ReadTime = json => {
    const text = json.content.reduce(
      (acc, { content }) =>
        acc + (content[0] && content[0].value ? ` ${content[0].value}` : ''),
      '',
    )

    const wordsPerMinute = 240
    const words = text.split(' ').length
    const lengthMin = Math.floor(words / wordsPerMinute)

    return lengthMin === 0 ? 1 : lengthMin
  }

  return (
    <ArticleContainer>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={image?.file?.url}
        slug={slug}
        isBlogPost
      />
      <StyledArticle>
        <HeroMobile>
          <ImageWrapper>
            <Image
              fluid={image?.fluid}
              alt={title}
              style={{ height: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </ImageWrapper>
          {photoBy && <PhotoBy>{photoBy}</PhotoBy>}
          <SaveArticleButton
            article={{
              slug,
              title,
              image,
              writer,
              date,
              id,
              preview,
              time: ReadTime(content.json),
            }}
            label={dictionary.saveForLater}
          />
        </HeroMobile>
        <Container>
          <DesktopHeaderGrid>
            <Left>
              <Heading>{title}</Heading>
              <Details>
                <Time>
                  {ReadTime(content.json)} {dictionary.minRead}
                </Time>
                <span></span>
                <Date>{date.split('.').join('')}</Date>
                <span></span>
                <Writer>{writer.name}</Writer>
                <ButtonWrapper>
                  <SaveArticleButton
                    article={{
                      slug,
                      title,
                      image,
                      writer,
                      date,
                      id,
                      preview,
                      time: ReadTime(content.json),
                    }}
                    label={dictionary.saveForLater}
                  />
                </ButtonWrapper>
              </Details>
              <Writers>
                <br />
                {coCreated && (
                  <>
                    <h3>coCreated by</h3>
                    {coCreated.map(({ title, slug, image, jobFunction }) => (
                      <Expert key={title} to={getPathname(node_locale, slug)}>
                        <Image fluid={image?.fluid} />
                        <div>
                          {/* {dictionary.coCreated} */}
                          {title} - {jobFunction}
                        </div>
                      </Expert>
                    ))}
                  </>
                )}
                {approvedBy && (
                  <>
                    <h3>Approved by</h3>
                    {approvedBy.map(({ title, slug, image, jobFunction }) => (
                      <Expert key={title} to={getPathname(node_locale, slug)}>
                        <Image fluid={image?.fluid} />
                        <div>
                          {/* {dictionary.approvedBy} */}
                          {title} - {jobFunction}
                        </div>
                      </Expert>
                    ))}
                  </>
                )}
              </Writers>
              <Preview>{preview}</Preview>
            </Left>
            <Right>
              <ImageWrapperDesktop>
                <Image
                  fluid={image?.fluid}
                  alt={title}
                  style={{ height: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
                {photoBy && (
                  <PhotoBy aspectRatio={image?.fluid.aspectRatio}>
                    {photoBy}
                  </PhotoBy>
                )}
              </ImageWrapperDesktop>
            </Right>
          </DesktopHeaderGrid>
          <DesktopContentGrid>
            <div>
              <Text>
                <RichText json={content.json} />
                {videoLink && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<iframe width="100%" height="400" src="${videoLink}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                    }}
                  />
                )}
              </Text>
              <ArticleShare slug={slug} title={title} />
              {webinar_url && (
                <WebinarForm>
                  <WebinarTitle>
                    Wil jij meedoen aan de gratis webinar over hevig menstrueel
                    bloedverlies? Laat hier je mailadres achter
                  </WebinarTitle>
                  <WebinarCapture placeholder='placeholder' />
                </WebinarForm>
              )}
            </div>
            <RelatedProductsColumn>
              {relatedProducts || relatedProduct ? (
                <RelatedProductsInner>
                  <SectionTitle>related products</SectionTitle>
                  {relatedProduct && (
                    <ArticleProductCard mini content={relatedProduct} />
                  )}
                  {relatedProducts ? (
                    <ProductDesktopWrapper>
                      {relatedProducts.map((product, i) => (
                        <ArticleProductCard key={i} mini content={product} />
                      ))}
                    </ProductDesktopWrapper>
                  ) : null}
                </RelatedProductsInner>
              ) : null}
            </RelatedProductsColumn>
          </DesktopContentGrid>
        </Container>
        {/* New field for more than one related product (Swiper) */}
        {relatedProducts && (
          <RelatedProductsMobile>
            <ArticleProductSwiper
              content={{
                title: 'related products',
                slideCategories: relatedProducts,
              }}
              mini
              overlay
            />
          </RelatedProductsMobile>
        )}
        {relatedProduct && (
          <RelatedProductsMobile>
            <SectionTitle>related products</SectionTitle>
            {relatedProduct && <ArticleProductCard content={relatedProduct} />}
          </RelatedProductsMobile>
        )}
      </StyledArticle>
      {/* <Comments id={id} slug={slug} /> */}
      {relatedArticles && (
        <ArticlesWrapper>
          <ArticleProductSwiper
            slidesMobile={1.3}
            content={{
              title: 'anderen lazen ook',
              slideCategories: relatedArticles,
            }}
            centerSlides
            slidesDesktop={3}
            disableSwiping
          />
        </ArticlesWrapper>
      )}
      <SectionBlock
        key={articlePagesFooterBlocks[0].title}
        content={articlePagesFooterBlocks[0].block}
      />
    </ArticleContainer>
  )
}

export const PageQuery = graphql`
  query Article($id: String!, $node_locale: String!) {
    contentfulArticle(id: { eq: $id }) {
      seoTitle
      seoDescription
      title
      id
      slug
      date(formatString: "DD MMM YYYY", locale: $node_locale)
      label {
        title
        colorCode
      }
      preview
      writer {
        name
      }
      coCreated {
        title
        jobFunction
        slug
        image {
          fluid(maxWidth: 40) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      approvedBy {
        title
        jobFunction
        slug
        image {
          fluid(maxWidth: 40) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      photoBy
      image {
        file {
          url
        }
        fluid(maxWidth: 700) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        json
      }
      videoLink
      relatedArticles {
        title
        slug
        content {
          json
        }
        label {
          title
          colorCode
        }
        id
        writer {
          name
        }
        preview
        date(formatString: "DD MMM YYYY", locale: $node_locale)
        image {
          file {
            url
          }
          fluid(maxWidth: 250) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      relatedProducts {
        title
        slug
        productDescriptionShort
        imageThumbnail {
          fluid(maxWidth: 265) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        shopifyProduct {
          handle
          variants {
            edges {
              node {
                price
              }
            }
          }
        }
      }
      relatedProduct {
        title
        slug
        productDescriptionShort
        imageThumbnail {
          fluid(maxWidth: 265) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        shopifyProduct {
          handle
          variants {
            edges {
              node {
                price
              }
            }
          }
        }
      }
    }
  }
`

export default Article
